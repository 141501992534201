import React from 'react';
import { browserHistory } from 'react-router';
import _ from 'lodash';
import * as campaignActions from '../../../../actions/admin/campaignActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';
import validator from 'validator';

import { connect } from 'react-redux';
import FormElement from '../../../shared/form/FormElement';

import CampaignVersionExposurePanel from '../../../shared/campaignVersionExposure/CampaignVersionExposurePanel';

import CurrencyDropbox from '../../../shared/dropbox/CurrencyDropbox';

export class CampaignEdit extends React.Component {

  constructor(props) {
    super(props);

    const {
      campaignDetails = {
        clientId:'',
        expiry: '',
        startDate: '',
        description:'',
        shortUrlCustomStringComponent:'',
        superCampaign:'',
        active: '',
        directShare: '',
        soretoTag: '',
        orderOriginCurrency: '',
        archived: false
      }
    } = props;

    this.state = {
      campaignDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
      campaignId: ''
    };
  }

  onChange(name, {target}) {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, name, target.value);
    this.setState({campaignDetails});
  }

  onChangeCheckbox(name, {target}) {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, name, target.checked);
    this.setState({campaignDetails});
  }

  onActiveChange(name, {target}) {
    const {campaignDetails} = this.state;
    if(target.value === 'true'){
      _.set(campaignDetails, 'archived', false);
    }
    _.set(campaignDetails, name, target.value);
    this.setState({campaignDetails});
  }

  componentWillMount() {
    var campaignId = this.props.params.campaignId;
    this.setState({ campaignId: campaignId });
    if(campaignId){
      this.props.dispatch(campaignActions.getCampaign(campaignId));
    }

  }

  componentDidMount() {
    if(this.props.location && this.props.location.state && this.props.location.state.fromCreatePage){
      this.props.router.replace({...this.props.location, state: null});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'success',
        message: 'New campaign added',
        timeout: 20000
      }));
    }
  }

  componentWillReceiveProps(nextProps) {
    if(_.get(nextProps,'campaign.expiry')){
      nextProps.campaign.expiry = new Date(nextProps.campaign.expiry);
      nextProps.campaign.startDate = new Date(nextProps.campaign.startDate);
      this.setState({ campaignDetails : nextProps.campaign});
    }
  }

  componentWillUpdate(nextProps){
    if(this.props.campaignEdit !=nextProps.campaignEdit){
      if(_.get(nextProps,'campaignEdit.description')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'success',
          message: 'Campaign saved with success',
          timeout: 20000
        }));
      }
      if(_.get(nextProps,'campaignEdit.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.campaignEdit.data.message,
          timeout: 10000
        }));
      }
    }
  }

  onChangeExpiry = (e) => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'expiry', e.value);
    this.setState({campaignDetails});
  }

  onChangeStartDate = (e) => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'startDate', e.value);
    this.setState({campaignDetails});
  }

  onChangeSlider = (e) => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'valueAmount', e.value);
    this.setState({campaignDetails});
  }

  onChangeOrderOriginalCurrencyStatus = e => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'orderOriginCurrency', e.value);
    this.setState({campaignDetails});
  };

  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const {campaignDetails,errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] && key !== 'shortUrlCustomStringComponent') {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        return;
      }
    }

    if( errors['shortUrlCustomStringComponent']) {
      checkErrors = true;
      this.setState({displayErrorMessage: true});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'error',
        message: 'Short Url Custom String must be lowercase and shorter then 20 characters with only "_" or "-"' ,
        timeout: 10000
      }));
      return;
    }

    if( !checkErrors ){
      campaignDetails.updatedAt = new Date();
      this.props.dispatch(campaignActions.updateCampaign(campaignDetails));
    }

  }

  validateForm() {
    const { campaignDetails, errors } = this.state;

    errors[ 'clientId' ] = validator.isEmpty(campaignDetails.clientId.toString());
    errors[ 'expiry' ] = validator.isEmpty(campaignDetails.expiry.toString());
    errors[ 'startDate' ] = validator.isEmpty(campaignDetails.startDate.toString());
    errors[ 'description' ] = validator.isEmpty( campaignDetails.description.toString());
    errors[ 'active' ] = validator.isEmpty( campaignDetails.active.toString() );
    errors[ 'directShare' ] = validator.isEmpty( campaignDetails.directShare.toString() );
    errors[ 'soretoTag' ] = validator.isEmpty( campaignDetails.soretoTag.toString() );
    
    const shortUrl = campaignDetails.shortUrlCustomStringComponent || '';

    if (!validator.isEmpty(shortUrl)){
      errors[ 'shortUrlCustomStringComponent' ] = (!validator.matches(shortUrl,/^[a-z\d_-]{2,20}$/));
    }
    else {
      errors[ 'shortUrlCustomStringComponent' ] = validator.isEmpty( shortUrl);
    }
    return this.setState({ errors });
  }

  render (){
    const {campaignDetails = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      clientId,
      expiry,
      startDate,
      description,
      shortUrlCustomStringComponent,
      superCampaign,
      active,
      directShare,
      soretoTag,
      archived,
      countryId,
      type,
      orderOriginCurrency,
    } = campaignDetails;

    return (
      <div className="campaign-new-campaign">
        <h3>Edit campaign</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Country'
            name='country'
            type='text'
            value= { countryId && this.props.countries ? this.props.countries.find(c => c._id === countryId).name : ''}
            id='form-country'
            required
            disabled={true}
          />

          <FormElement
            label='Client Id'
            name='clientId'
            type='text'
            onChange={this.onChange.bind(this, 'clientId')}
            value={clientId}
            error={errors.clientId}
            id='form-clientId'
            required
            disabled={true}
          />

          <FormElement
            label='Name'
            name='description'
            type='text'
            onChange={this.onChange.bind(this, 'description')}
            value={description}
            error={errors.description}
            id='form-description'
            required
          />

          <FormElement
            label='Start Date (This field uses UTC time)'
            name='startDate'
            type='calendarWithTime'
            onChange={(e) => this.onChangeStartDate(e)}
            value={startDate}
            id='start-date'
            required
            style={{ marginLeft: '5px', width: '300px' }}
          />

          <FormElement
            label='Expiry (This field uses UTC time)'
            name='expiry'
            type='calendarWithTime'
            onChange={(e) => this.onChangeExpiry(e)}
            value={expiry}
            id='expiry'
            required
            style={{ marginLeft: '5px', width: '300px'}}
          />

          <FormElement
            label='Short Url Custom String (lower case, 20 chars max, can have "_", "-" )'
            name='shortUrlCustomStringComponent'
            type='text'
            onChange={this.onChange.bind(this, 'shortUrlCustomStringComponent')}
            value={shortUrlCustomStringComponent ? shortUrlCustomStringComponent: ''}
            error={errors.shortUrlCustomStringComponent}
            id='form-shortUrlCustomStringComponent'
            required
          />

          <FormElement
            label='Direct share link allowed'
            name='directShare'
            type='select'
            onChange={this.onChange.bind(this, 'directShare')}
            value={directShare}
            error={errors.directShare}
            id='form-directShare'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Soreto tag allowed'
            name='soretoTag'
            type='select'
            onChange={this.onChange.bind(this, 'soretoTag')}
            value={soretoTag}
            error={errors.soretoTag}
            id='form-soretoTag'
            required
          >  
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>
          
          <FormElement
            label='Super Campaign'
            name='superCampaign'
            type='select'
            onChange={this.onActiveChange.bind(this, 'superCampaign')}
            value={superCampaign}
            error={errors.active}
            id='form-active'
            required>

            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={this.onActiveChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
            required
          >
                     
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            id='form-type'
            required
          >  
            <option value="on_site_referral">On Site Referral</option>
            <option value="marketplace">Marketplace</option>
          </FormElement>

          <CurrencyDropbox 
            onChange={e => this.onChangeOrderOriginalCurrencyStatus(e)}
            value={orderOriginCurrency}
          />

          <FormElement
            label='Archived'
            name='archived'
            type='checkbox'
            onChange={this.onChangeCheckbox.bind(this, 'archived') }
            disabled={(active == 'true' || active === true)}
            checked={(!!(archived))}
            error={errors.archived}
            id='form-active'
          />
        </form>
        <br />
        <button onClick={()=>this.onSubmit()}>Edit campaign</button>

        <button
          className="button icon-left"
          onClick={() => browserHistory.push('/admin/campaign')}>
          Back
        </button>

        <CampaignVersionExposurePanel campaignId={this.state.campaignId}></CampaignVersionExposurePanel>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    campaign: store.adminCampaign.campaign,
    campaignEdit: store.adminCampaign.campaignEdit,
    countryId: store.adminLayout.layoutCountrySet,
    countries: store.adminLayout.layoutCountriesGet
  };
})(CampaignEdit);
