/* eslint-disable quotes */
/* eslint-disable indent */
import React from "react";
import { Dialog } from "primereact/components/dialog/Dialog";
import { Fieldset } from "primereact/components/fieldset/Fieldset";
import _ from "lodash";

import FormElement from "../../shared/form/FormElement";

import clientService from "../../../utils/services/client";
import campaignService from "../../../utils/services/campaign";
import campaignVersionService from "../../../utils/services/campaignVersion";
import { Checkbox } from "primereact/components/checkbox/Checkbox";

import "../copyCampaignVersion/CopyCampaignVersion.scss";
import DataBox from "../../client/pages/analytics/Analytics.DataBox";

let _assets = {
    rewards: {
        name: "Reward",
        entries: ["all"],
    },
    displayBlocks: {
        name: "Display Blocks",
        entries: [
            "lightbox",
            "sharestaticpage",
            "interstitial",
            "shareviaemail",
            "lightboxwrapper",
        ],
    },
    emails: {
        name: "Emails",
        entries: [
            "post_purchase_discount_reward_email",
            "baa-forward-email",
            "share_with_friend_email",
            "baa-thankyou-email",
            "baa-friend-email",
            "post_purchase_friend_reward_email",
            "reward_email",
            "share_with_friend_email_reminder",
        ],
    },
    globalVars: {
        name: "Global Vars",
        entries: ["all"],
    },
};

let _assets_BoostPresetOptions = {
    base: {
        rewards: {
            all: true,
        },
        displayBlocks: {
            interstitial: true,
            lightbox: true,
        },
        emails: {
            post_purchase_discount_reward_email: true,
            "baa-forward-email": true,
            share_with_friend_email: true,
            "baa-thankyou-email": true,
            "baa-friend-email": true,
            post_purchase_friend_reward_email: true,
            reward_email: true,
            share_with_friend_email_reminder: true,
        },
        globalVars: {
            all: true,
        },
    },
    template: {
        displayBlocks: {
            sharestaticpage: true,
            lightboxwrapper: true,
        },
    },
};

export class CopyCampaignVersion extends React.Component {
    constructor(props) {
        super(props);

        this.state = this.baseState();
    }

    baseState() {
        let baseStateObj = {
            loading: true,
            options: this.props.options,
            selectedCampaignTemplate: null,
            showReplaceablesPanel: false,
            selectedTemplateCampaignAssets: {},
            baseOptions: {},
            templateOptions: {},
            templateReplaceables: {},
        };

        let presetAssetOptions = null;

        if (this.props.options.mode == "boost") {
            presetAssetOptions = _assets_BoostPresetOptions;
        }

        for (let asset in _assets) {
            baseStateObj.baseOptions[asset] = {};
            baseStateObj.templateOptions[asset] = {};
            baseStateObj.templateReplaceables[asset] = {};

            if (!presetAssetOptions) continue;

            for (let entry of _assets[asset].entries) {
                if (
                    presetAssetOptions.base[asset] &&
                    presetAssetOptions.base[asset][entry]
                ) {
                    baseStateObj.baseOptions[asset][entry] = true;
                }

                if (
                    presetAssetOptions.template[asset] &&
                    presetAssetOptions.template[asset][entry]
                ) {
                    baseStateObj.templateOptions[asset][entry] = true;
                }
            }
        }

        return baseStateObj;
    }

    componentWillUnmount() {
        this.isCancelled = true;
    }

    componentWillUpdate(nextProps) {
        if (
            JSON.stringify(this.props.options) !=
            JSON.stringify(nextProps.options)
        ) {
            if (nextProps.options.mode == "boost") {
                this.loadTemplates();
                this.setState({
                    ...this.baseState(),
                    ...{ options: nextProps.options },
                });
            }
        }

        if (
            this.state.showReplaceablesPanel &&
            !this.recursiveTreeChecker(this.state.templateOptions)
        ) {
            this.setState({ showReplaceablesPanel: false });
        }

        if (
            !_.isEmpty(this.state.templateOptions) &&
            !_.isEmpty(this.state.selectedTemplateCampaignAssets)
        ) {
            let selectedTemplateCampaignAssets = this.state
                .selectedTemplateCampaignAssets;
            for (let asset of Object.keys(this.state.templateOptions)) {
                for (let assetType of Object.keys(
                    this.state.templateOptions[asset]
                )) {
                    switch (asset) {
                        case "displayBlocks":
                            if (!selectedTemplateCampaignAssets.codeBlocks) {
                                break;
                            }

                            selectedTemplateCampaignAssets.codeBlocks.map(
                                (cb) => {
                                    if (cb.displayBlockType == assetType) {
                                        if (
                                            this.state.templateOptions[asset][
                                                assetType
                                            ]
                                        ) {
                                            cb.show = true;
                                        } else {
                                            cb.show = false;
                                        }
                                    }

                                    return cb;
                                }
                            );

                            break;
                        case "emails":
                            if (selectedTemplateCampaignAssets.emails) {
                                selectedTemplateCampaignAssets.emails.map(
                                    (cb) => {
                                        if (cb.type == assetType) {
                                            if (
                                                this.state.templateOptions[
                                                    asset
                                                ][assetType]
                                            ) {
                                                cb.show = true;
                                            } else {
                                                cb.show = false;
                                            }
                                        }

                                        return cb;
                                    }
                                );
                            }
                            break;
                    }
                }                
            }

            if (
                JSON.stringify(this.state.selectedTemplateCampaignAssets) !=
                JSON.stringify(selectedTemplateCampaignAssets)
            ) {
                this.setState({
                    selectedTemplateCampaignAssets: selectedTemplateCampaignAssets,
                });
            }
        }
    }

    recursiveTreeChecker = (object) => {
        for (let prop of Object.keys(object)) {
            if (
                object[prop] === true ||
                (object[prop] instanceof Object &&
                    this.recursiveTreeChecker(object[prop]))
            ) {
                return true;
            }
        }
    };

    loadTemplates = () =>
        clientService
            .getClientPage({ template_$: true })
            .then((res) =>
                res.data.page.map((item) =>
                    _.pick(item, ["_id", "name", "customIdentifier"])
                )
            )
            .then((templates) => Promise.all(templates.map(this.withVersion)))
            .then((templatesWithVersion) =>
                this.setState({ templates: templatesWithVersion })
            )
            .finally(() => {
                this.setState({ loading: false });
            });

    withVersion = (template) =>
        campaignService.getAllCampaigns(template._id).then((res) => ({
            ...template,
            versions:
                res.data &&
                res.data.map((item) => _.pick(item, "_id", "description")),
        }));

    copyCampaignVersion = () => {
        // validations

        let templateAssetSelected = this.recursiveTreeChecker(
            this.state.templateOptions
        );

        if (templateAssetSelected && !this.state.selectedCampaignTemplate) {
            alert("Please, select a template Campaign");
            return;
        }

        if (!this.state.showReplaceablesPanel && templateAssetSelected) {
            this.setState({ showReplaceablesPanel: true });

            return;
        }

        this.setState({ loading: true });

        // build the request payload
        let payload = {
            campaignVersionBaseId: this.state.options.campaignVersionId,
            campaignTemplateId: this.state.selectedCampaignTemplate,
            copyOptions: {
                baseOptions: this.state.baseOptions,
                templateOptions: this.state.templateOptions,
            },
            templateReplaceables: this.state.templateReplaceables,
        };

        campaignVersionService
            .copyCampaignVersionTemplate(payload)
            .then(() => {
                // hide modal
                const newOpt = this.state.options;
                newOpt.copyCampaignVersionModalOpened = false;
                this.setState({
                    options: newOpt,
                });

                if (this.props.onHide) {
                    this.props.onHide();
                }

                alert("The campaign version was copied!");
            })
            .catch((error) => {
                alert(
                    `An error happened creating the Campaign Version: ${error}`
                );
            })
            .finally(() => {
                this.setState({ loading: false });
            });
    };

    render() {
        const mode = {
            /**
             *
             * BOOST MODE
             *
             */
            boost: () => {
                const {
                    templates,
                    selectedTemplate,
                    selectedCampaignTemplate,
                } = this.state;

                let lines = [];

                for (let asset in _assets) {
                    /**
                     * ASSET CATEGORY HEADER
                     */
                    lines.push(
                        <div style={{ display: "flex" }}>
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    fontWeight: "bold",
                                    marginTop: "10px",
                                    fontSize: "20px",
                                }}
                            >
                                {_assets[asset].name}
                            </div>
                        </div>
                    );

                    /**
                     * ADD THE OPTION LINES
                     */
                    let optionI = 0;
                    for (let entry of _assets[asset].entries) {
                        optionI++;
                        lines.push(
                            <div
                                style={{
                                    display: "flex",
                                    color: "black",
                                    padding: "2px 0px 2px 0px",
                                    backgroundColor:
                                        optionI % 2 === 0
                                            ? "lightgrey"
                                            : "gray",
                                }}
                            >
                                {/* ASSET NAME */}
                                <div
                                    style={{
                                        flex: 5,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "7px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                        }}
                                    >
                                        {entry}
                                    </div>
                                </div>

                                {/* BASE CHECKBOX */}
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "7px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Checkbox
                                            id={`${entry}`}
                                            checked={
                                                this.state.baseOptions[asset][
                                                    `${entry}`
                                                ]
                                            }
                                            onChange={(e) => {
                                                /**
                                                 * CHANGE TEMPLATE STATE
                                                 */
                                                let templateOptions = this.state
                                                    .templateOptions;
                                                let templateAssetOptions = this
                                                    .state.templateOptions[
                                                    asset
                                                ];

                                                templateAssetOptions[
                                                    entry
                                                ] = !e.checked;

                                                templateOptions[
                                                    asset
                                                ] = templateAssetOptions;

                                                /**
                                                 * CHANGE BASE STATE
                                                 */
                                                let baseOptions = this.state
                                                    .baseOptions;
                                                let baseAssetOptions = this
                                                    .state.baseOptions[asset];

                                                baseAssetOptions[entry] =
                                                    e.checked;

                                                baseOptions[
                                                    asset
                                                ] = baseAssetOptions;

                                                this.setState({
                                                    templateOptions,
                                                    baseOptions,
                                                });
                                            }}
                                        ></Checkbox>
                                    </div>
                                </div>

                                {/* NONE CHECKBOX */}
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "7px",
                                    }}
                                >
                                    <div
                                        style={{
                                            flex: 1,
                                            display: "flex",
                                            alignItems: "center",
                                            justifyContent: "center",
                                        }}
                                    >
                                        <Checkbox
                                            id={`${entry}`}
                                            checked={
                                                typeof this.state.baseOptions[
                                                    asset
                                                ][`${entry}`] === "undefined" &&
                                                typeof this.state
                                                    .templateOptions[asset][
                                                    `${entry}`
                                                ] === "undefined"
                                            }
                                            onChange={(e) => {
                                                /**
                                                 * CHANGE TEMPLATE STATE
                                                 */
                                                let templateOptions = this.state
                                                    .templateOptions;
                                                let templateAssetOptions = this
                                                    .state.templateOptions[
                                                    asset
                                                ];

                                                delete templateAssetOptions[
                                                    entry
                                                ];

                                                templateOptions[
                                                    asset
                                                ] = templateAssetOptions;

                                                /**
                                                 * CHANGE BASE STATE
                                                 */
                                                let baseOptions = this.state
                                                    .baseOptions;
                                                let baseAssetOptions = this
                                                    .state.baseOptions[asset];

                                                delete baseAssetOptions[entry];

                                                baseOptions[
                                                    asset
                                                ] = baseAssetOptions;

                                                this.setState({
                                                    templateOptions,
                                                    baseOptions,
                                                });
                                            }}
                                        ></Checkbox>
                                    </div>
                                </div>

                                {/* TEMPLATE CHECKBOX */}
                                <div
                                    style={{
                                        flex: 1,
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "7px",
                                    }}
                                >
                                    <div style={{ flex: 1 }}>
                                        <Checkbox
                                            id={`${entry}`}
                                            checked={
                                                this.state.templateOptions[
                                                    asset
                                                ][`${entry}`]
                                            }
                                            onChange={(e) => {
                                                /**
                                                 * CHANGE TEMPLATE STATE
                                                 */
                                                let templateOptions = this.state
                                                    .templateOptions;
                                                let templateAssetOptions = this
                                                    .state.templateOptions[
                                                    asset
                                                ];

                                                templateAssetOptions[entry] =
                                                    e.checked;

                                                templateOptions[
                                                    asset
                                                ] = templateAssetOptions;

                                                /**
                                                 * CHANGE BASE STATE
                                                 */
                                                let baseOptions = this.state
                                                    .baseOptions;
                                                let baseAssetOptions = this
                                                    .state.baseOptions[asset];

                                                baseAssetOptions[
                                                    entry
                                                ] = !e.checked;

                                                baseOptions[
                                                    asset
                                                ] = baseAssetOptions;

                                                this.setState({
                                                    templateOptions,
                                                    baseOptions,
                                                });
                                            }}
                                        ></Checkbox>
                                    </div>
                                </div>
                            </div>
                        );
                    }
                }

                /**
                 * ADD HEADER
                 */
                lines.unshift(
                    <div style={{ display: "flex" }}>
                        {/* ASSET NAME */}
                        <div
                            style={{
                                flex: 5,
                                display: "flex",
                                flexDirection: "column",
                                gap: "7px",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                }}
                            ></div>
                        </div>

                        {/* BASE CHECKBOX */}
                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: "7px",
                            }}
                        >
                            <div
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    alignItems: "center",
                                    justifyContent: "center",
                                }}
                            >
                                Base
                            </div>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: "7px",
                            }}
                        >
                            <div style={{ flex: 1 }}>None</div>
                        </div>

                        <div
                            style={{
                                flex: 1,
                                display: "flex",
                                flexDirection: "column",
                                gap: "7px",
                            }}
                        >
                            <div style={{ flex: 1 }}>Template</div>
                        </div>
                    </div>
                );

                /**
                 * REPLEACEABLES PANEL
                 */
                let repleaceablesPanel = (
                    repleaceables,
                    level1,
                    level2,
                    level3
                ) => {
                    if (!this.state.templateReplaceables[level1]) {
                        this.state.templateReplaceables[level1] = {};
                    }
                    if (!this.state.templateReplaceables[level1][level2]) {
                        this.state.templateReplaceables[level1][level2] = {};
                    }
                    if (
                        !this.state.templateReplaceables[level1][level2][level3]
                    ) {
                        this.state.templateReplaceables[level1][level2][
                            level3
                        ] = {};
                    }

                    return (
                        <div
                            style={{
                                flex: 1,
                                width: "100%",
                                color: "black",
                                paddingLeft: "50px",
                            }}
                        >
                            {repleaceables && repleaceables.length > 0 ? (
                                repleaceables.map((v, i) => {
                                    return (
                                        <div
                                            style={{
                                                fontSize: "15px",
                                                display: "flex",
                                                alignItems: "center",
                                                flexDirection: "row",
                                                padding: "2px 0px 2px 0px",
                                                margin: "2px 0px 2px 0px",
                                                backgroundColor:
                                                    i % 2 === 0
                                                        ? "lightgray"
                                                        : "gray",
                                            }}
                                        >
                                            <div
                                                style={{
                                                    flex: 1,
                                                    textAlign: "justify",
                                                }}
                                            >
                                                <label>{v}</label>
                                            </div>

                                            <div
                                                style={{
                                                    flex: 1,
                                                }}
                                            >
                                                <input
                                                    type="text"
                                                    value={
                                                        this.state
                                                            .templateReplaceables[
                                                            level1
                                                        ][level2][level3][v]
                                                    }
                                                    style={{
                                                        fontSize: "15px",
                                                        padding: "0px"
                                                    }}
                                                    onChange={(e) => {
                                                        let replaceables = this
                                                            .state
                                                            .templateReplaceables;
                                                        replaceables[level1][
                                                            level2
                                                        ][level3][v] =
                                                            e.target.value;

                                                        this.setState({
                                                            templateReplaceables: replaceables,
                                                        });
                                                    }}
                                                ></input>
                                            </div>
                                        </div>
                                    );
                                })
                            ) : (
                                <div>-</div>
                            )}
                        </div>
                    );
                };

                let modalContent = (
                    <div>
                        {/* 
                            ----------------
                            BODY
                            ----------------
                        */}
                        <div style={{ display: "flex" }}>
                            {/*
                             */}
                            <Fieldset
                                legend="Template Selection"
                                style={{
                                    flex: 1,
                                    display: "flex",
                                    justifyContent: "center",
                                }}
                            >
                                <FormElement
                                    label="Select the Boost Template Campaign"
                                    type="dropdown"
                                    id="form-template"
                                    style={{
                                        width: "300px",
                                        marginLeft: "5px",
                                    }}
                                    name="selecttedTemplate"
                                    value={selectedTemplate}
                                    options={
                                        templates &&
                                        templates.map((template) => ({
                                            label: template.name,
                                            value: template,
                                        }))
                                    }
                                    onChange={(e) => {
                                        this.setState({
                                            selectedTemplate: e.value,
                                        });
                                    }}
                                    error={null}
                                    placeholder="Campaign"
                                    required
                                />
                                <br />
                                <FormElement
                                    label="Select the Boost Template Version"
                                    type="dropdown"
                                    id="form-template-version"
                                    style={{
                                        width: "300px",
                                        marginLeft: "5px",
                                    }}
                                    name="selectedVersion"
                                    value={selectedCampaignTemplate}
                                    options={
                                        selectedTemplate &&
                                        selectedTemplate.versions &&
                                        selectedTemplate.versions.map(
                                            (version) => ({
                                                label: version.description,
                                                value: version._id,
                                            })
                                        )
                                    }
                                    onChange={(e) => {
                                        let baseState = this.baseState();
                                        this.setState({
                                            selectedCampaignTemplate: e.value,
                                            templateReplaceables:
                                                baseState.templateReplaceables,
                                        });

                                        this.setState({ loading: true });
                                        campaignVersionService
                                            .getCampaignVersionAssets(e.value)
                                            .then((res) => {
                                                this.setState({
                                                    selectedTemplateCampaignAssets:
                                                        res.data.resultData,
                                                });
                                            })
                                            .finally(() => {
                                                this.setState({
                                                    loading: false,
                                                });
                                            });
                                    }}
                                    error={null}
                                    placeholder="Version"
                                    required
                                />
                            </Fieldset>

                            <Fieldset
                                legend="Copy from"
                                style={{
                                    flex: 1.5,
                                }}
                            >
                                <div
                                    style={{
                                        display: "flex",
                                        flexDirection: "column",
                                        gap: "5px",
                                    }}
                                >
                                    {lines}
                                </div>
                            </Fieldset>

                            {this.state.showReplaceablesPanel && (
                                <Fieldset
                                    legend="Replaceables"
                                    style={{
                                        flex: 2,
                                        height: "680px",
                                    }}
                                >
                                    <div
                                        style={{
                                            overflow: "scroll",
                                            height: "627px",
                                            display: "flex",
                                            flexDirection: "column",
                                            alignItems: "start",
                                        }}
                                    >
                                        {this.state
                                            .selectedTemplateCampaignAssets &&
                                            this.state
                                                .selectedTemplateCampaignAssets
                                                .codeBlocks && (
                                                <div
                                                    style={{
                                                        fontSize: "20px",
                                                        marginBottom: "20px",
                                                        width: "100%",
                                                        backgroundColor: "blue",
                                                        color: "white",
                                                    }}
                                                >
                                                    Code Blocks
                                                </div>
                                            )}

                                        {this.state
                                            .selectedTemplateCampaignAssets &&
                                            this.state
                                                .selectedTemplateCampaignAssets
                                                .codeBlocks &&
                                            this.state.selectedTemplateCampaignAssets.codeBlocks
                                                .filter((cb) => cb.show)
                                                .map((e) => {
                                                    
                                                    return (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "start",
                                                                flexDirection:
                                                                    "column",
                                                                marginBottom:
                                                                    "20px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {/* 
                                                                ======================
                                                                DISPLAY BLOCK TYPE 
                                                                ======================
                                                            */}
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        "18px",
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "start",
                                                                    flexDirection:
                                                                        "column",
                                                                    marginBottom:
                                                                        "20px",
                                                                    backgroundColor:
                                                                        "yellow",
                                                                }}
                                                            >
                                                                {
                                                                    e.displayBlockType
                                                                }
                                                            </div>

                                                            {/* 
                                                                ======================
                                                                DISPLAY JAVASCRIPT 
                                                                ======================
                                                            */}
                                                            <div className="repleaceableTypeHeader">
                                                                Javascript
                                                            </div>

                                                            {repleaceablesPanel(
                                                                e.javascript_replaceables,
                                                                "codeBlocks",
                                                                e.displayBlockType,
                                                                "javascript"
                                                            )}

                                                            {/* 
                                                                ======================
                                                                DISPLAY SCSS 
                                                                ======================
                                                            */}
                                                            <div className="repleaceableTypeHeader">
                                                                SCSS
                                                            </div>
                                                            {repleaceablesPanel(
                                                                e.scss_replaceables,
                                                                "codeBlocks",
                                                                e.displayBlockType,
                                                                "scss"
                                                            )}

                                                            {/* 
                                                                ======================
                                                                DISPLAY HTML 
                                                                ======================
                                                            */}
                                                            <div className="repleaceableTypeHeader">
                                                                HTML
                                                            </div>
                                                            {repleaceablesPanel(
                                                                e.htmlBody_replaceables,
                                                                "codeBlocks",
                                                                e.displayBlockType,
                                                                "htmlBody"
                                                            )}

                                                            <hr />
                                                        </div>
                                                    );
                                                })}

                                        {this.state
                                            .selectedTemplateCampaignAssets &&
                                            this.state
                                                .selectedTemplateCampaignAssets
                                                .emails && (
                                                <div
                                                    style={{
                                                        fontSize: "20px",
                                                        marginBottom: "20px",
                                                        width: "100%",
                                                        backgroundColor: "blue",
                                                        color: "white",
                                                    }}
                                                >
                                                    Emails
                                                </div>
                                            )}

                                        {this.state
                                            .selectedTemplateCampaignAssets &&
                                            this.state
                                                .selectedTemplateCampaignAssets
                                                .emails &&
                                            this.state.selectedTemplateCampaignAssets.emails
                                                .filter((cb) => cb.show)
                                                .map((e) => {
                                                    return (
                                                        <div
                                                            style={{
                                                                display: "flex",
                                                                alignItems:
                                                                    "start",
                                                                flexDirection:
                                                                    "column",
                                                                marginBottom:
                                                                    "20px",
                                                                width: "100%",
                                                            }}
                                                        >
                                                            {/* 
                                                                ======================
                                                                EMAIL TYPE 
                                                                ======================
                                                            */}
                                                            <div
                                                                style={{
                                                                    fontSize:
                                                                        "18px",
                                                                    display:
                                                                        "flex",
                                                                    alignItems:
                                                                        "start",
                                                                    flexDirection:
                                                                        "column",
                                                                    marginBottom:
                                                                        "20px",
                                                                    backgroundColor:
                                                                        "yellow",
                                                                }}
                                                            >
                                                                {e.type}
                                                            </div>

                                                            {Object.keys(
                                                                e.templateValues_repleaceables
                                                            ).map((tr) => {
                                                                return (
                                                                    <div>
                                                                        <div className="repleaceableTypeHeader">
                                                                            {tr}
                                                                        </div>
                                                                        {repleaceablesPanel(
                                                                            e
                                                                                .templateValues_repleaceables[
                                                                                tr
                                                                            ],
                                                                            "emails",
                                                                            e.type,
                                                                            tr
                                                                        )}
                                                                    </div>
                                                                );
                                                            })}

                                                            <hr />
                                                        </div>
                                                    );
                                                })}
                                    </div>
                                </Fieldset>
                            )}
                        </div>

                        {/* 
                            ----------------
                            FOOTER
                            ----------------
                        */}
                        <div
                            style={{
                                display: "flex",
                                justifyContent: "end",
                                paddingTop: "10px",
                            }}
                        >
                            <button
                                className="ui-button"
                                onClick={() => this.copyCampaignVersion()}
                            >
                                {!this.recursiveTreeChecker(
                                    this.state.templateOptions
                                )
                                    ? "CREATE"
                                    : "NEXT"}
                            </button>
                        </div>
                    </div>
                );

                let loadingContent = <div style={{ flex: 1 }}>Wait...</div>;

                return (
                    <Dialog
                        header="Boost Campaign Version"
                        visible={
                            this.state.options.copyCampaignVersionModalOpened
                        }
                        height="850px"
                        width="1500px"
                        modal={true}
                        onHide={() => {
                            const newOpt = this.state.options;
                            newOpt.copyCampaignVersionModalOpened = false;
                            this.setState({
                                options: newOpt,
                            });

                            if (this.props.onHide) {
                                this.props.onHide();
                            }
                        }}
                    >
                        {this.state.loading ? loadingContent : modalContent}
                    </Dialog>
                );
            },
        };

        return mode[this.state.options.mode]();
    }
}
