import React from "react";
import config from "../../../config/config";
import { authService } from "../../../utils/services/auth";
import userService from "../../../utils/services/user";
import { connect } from "react-redux";
import * as clientGlobalMessageActions from "../../../actions/globalMessageActions";
import * as indexActions from '../../../actions/index';
import GlobalMessages from '../globalMessage/GlobalMessage';
import { browserHistory } from 'react-router';

@connect((store) => {
    return {
        baseUrl: config.URL.BASEFRONT,
        globalMessage: store.globalMessages.message,
    };
})
export default class TwoFactorVerification extends React.Component {
    constructor() {
        super();
        this.state = {
            authService: authService,
            email: "",
            token: "",
            indexActions: indexActions, 
            globalMessageActions: clientGlobalMessageActions,
        };

        this.login2Fa = this.login2Fa.bind(this);
        this.goToPage = this.goToPage.bind(this);
    }

    componentWillMount(){

      var twoFactorVerificationEmail = localStorage.getItem('twoFactorVerificationEmail');

      if(!twoFactorVerificationEmail){
        this.goToPage('/login')
      }

      this.setState({ email: twoFactorVerificationEmail });
    }

    login2Fa(e) {

      e.preventDefault();

      var that = this;

      this.state.authService
          .passwordlessLogin(this.state.email, this.state.token)
          .then((passwordlessResult) => {

              if(passwordlessResult.status != 200){
                that.props.dispatch(
                  that.state.globalMessageActions.showMessage({
                      type: "error",
                      message: 'Invalid verification code',
                      timeout: 3000,
                  })
              );
              }

              localStorage.removeItem('twoFactorVerificationEmail');

              userService.getCurrent().then((res) => {
                  //check if in correct context

                  that.props.dispatch(that.state.indexActions.updateLoginCtx(res.data));
                  // segmentAnalytics.identify(res.data);
                  // trackingService.login(res.data , 'form');
                  
                  if (res.data.roles.some((s) => s.name === "clientUser")) {
                      this.goToPage("/client");
                  } else if (res.data.roles.some((s) => s.name === "user")) {
                      this.goToPage("/user");
                  } else if (res.data.roles.some((s) => s.name === "admin")) {
                      this.goToPage("/admin");
                  }
              });
          })
          .catch((err) => {
            
              that.props.dispatch(
                  that.state.globalMessageActions.showMessage({
                      type: "error",
                      message: 'Invalid verification code',
                      timeout: 3000,
                  })
              );
          });
    }

    goToPage(page) {
      browserHistory.push(page);
    }

    render() {
        return (
            <div className="front-page grid-container grid-parent">
                <div className="grid-100 front-page-mid-section grid-parent">
                    <GlobalMessages message={this.props.globalMessage} />

                    <div className="grid-100 front-page-form-holder text-center">
                        <div className="soreto__logo"></div>
                        <div className="form-container">
                            <form onSubmit={(e) => this.login2Fa(e)}>
                                <p className="form-container__title">
                                    Enter the verification code sent to your email.
                                </p>
                                <div className="front-page-input-holder">
                                    <input
                                        required
                                        value={this.state.token}
                                        onChange={(e) => {
                                            this.setState({
                                                token: e.target.value,
                                            });
                                        }}
                                        type="text"
                                        placeholder="verification code"
                                    />
                                </div>
                                <div>
                                    <button className="login--btn">
                                        VERIFY
                                    </button>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>

                {/* <h1>OLA!</h1>
                <label htmlFor="2fa">2FA code</label>
                <input
                    type="text"
                    id="2fa"
                    value={this.state.token}
                    onChange={(e) => {
                        this.setState({ token: e.target.value });
                    }}
                />
                <button type="button" onClick={this.login2Fa}>
                    VALIDATE
                </button> */}
            </div>
        );
    }
}
