import React from 'react';
import LoadingOverlay from 'react-loading-overlay';
import Footer from './footer/Footer';

import { GlobalMessages } from '../../shared/globalMessage/GlobalMessage';
import * as layoutActions from '../../../actions/admin/layoutActions';
import dynamicMenu from '../../../utils/services/dynamicMenu';
import { connect } from 'react-redux';

import { Menubar } from 'primereact/components/menubar/Menubar';
import { Button } from 'primereact/components/button/Button';

import config from '../../../config/config';

require('../../shared/sass/style.scss');
require('./_layout.scss');

export let countryId = '';

export class Layout extends React.Component {
  constructor(props) {
    super(props);
    this.countries = [];
    this.state = {
      countryId: countryId,
      dynamicMenus: []
    };
  }

  static defaultProps = {
    storage: localStorage,
    storageKey: 'countryId'
  };

  componentDidMount() {
    this.props.dispatch(layoutActions.getCountries());

    dynamicMenu.getDynamicUserMenus()
      .then((result) => {
        if(result){
          this.setState({dynamicMenus : result});
        }
      })
      .catch((e) => {
        console.error(`Error loading user dynamic menus ${e}`);
      });
  }

  componentDidUpdate(nextProps) {
    if (this.props.countries !== nextProps.countries) {
      this.setCountryFromStorage();
      window.addEventListener('storage', this.setCountryFromStorage);
    }
  }

  componentWillUnmount = () => {
    window.removeEventListener('storage', this.setCountryFromStorage);
  };

  setCountryFromStorage = () => {
    const { countries, storage, storageKey } = this.props;
    if (countries && Array.isArray(countries)) {
      countries.forEach(country => {
        this.countries.push({ label: country.code, value: country._id });
      });
    }

    if (storage.getItem(storageKey) === null) {
      this.countries.forEach(country => {
        if (country.label === 'GB') {
          countryId = country.value;
        }
      });
    } else {
      countryId = JSON.parse(storage.getItem(storageKey));
    }

    if (countryId) {
      this.setState({ countryId });
      this.props.dispatch(layoutActions.setCountry(countryId));
    }
  };

  logout = () => {
    var query = '/logout';
    this.props.router.push({ pathname: query });
  };

  onSetCountry = e => {
    this.setState({ countryId: e.value });
    this.props.dispatch(layoutActions.setCountry(e.value));
  };

  render() {

    document.body.style.overflow = this.props.loadSipperEnabled ? 'hidden' : 'auto';
    if(this.props.loadSipperEnabled) window.scrollTo(0,0);

    let { dynamicMenus } = this.state;

    var items = [
      // {
      //   label: 'New Analytics (Beta)',
      //   icon: 'fa-area-chart',
      //   command: () => {
      //     window.location.href = `${config.URL.NEWANALYTICS}`;
      //   }
      // },
      {
        label: 'Statistics',
        icon: 'fa-area-chart',
        items: [
          {
            label: 'Clients',
            icon: 'fa-bar-chart',
            items: [
              {
                label: 'On-site referral',
                icon: 'fa-building',
                command: () => {
                  this.props.router.push({ pathname: '/admin/reports/liveStats' });
                }   
              },
              {
                label: 'Marketplace',
                icon: 'fa-shopping-basket',
                command: () => {
                  this.props.router.push({ pathname: '/admin/reports/liveStatsMarketplace' });
                }
              }
            ]
          },
          {
            label: 'Clients Summary',
            icon: 'fa-bar-chart',
            items: [
              {
                label: 'On-site referral',
                icon: 'fa-building',
                command: () => {
                  this.props.router.push({ pathname: '/admin/reports/liveStatsSummary' });
                }
              },
              {
                label: 'Marketplace',
                icon: 'fa-shopping-basket',
                command: () => {
                  this.props.router.push({ pathname: '/admin/reports/liveStatsSummaryMarketPlace' });
                }
              }
            ]
          },
          {
            label: 'Clients Per Type',
            icon: 'fa-bar-chart',
            items: [
              {
                label: 'On-site referral',
                icon: 'fa-building',
                command: () => {
                  this.props.router.push({ pathname: '/admin/reports/liveStatsValidity' });
                }
              }
            ]
          },
          {
            label: 'Client Commission Per Month',
            icon: 'fa-bar-chart',
            command: () => {
              this.props.router.push({ pathname: '/admin/reports/liveStatsCommissionPerMonth' });
            }
          }
        ]
      },
      {
        label: 'Client Area',
        icon: 'fa-black-tie',
        items: [
          {
            label: 'Clients',
            icon: 'fa-industry',
            command: () => {
              this.props.router.push({ pathname: '/admin/client' });
            }
          }
        ]
      },
      {
        label: 'Campaign Area',
        icon: 'fa-edit',
        items: [
          {
            label: '1) Campaign',
            command: () => {
              this.props.router.push({ pathname: '/admin/campaign' });
            }
          },
          {
            label: '2) Campaign Version',
            command: () => {
              this.props.router.push({ pathname: '/admin/campaignVersion' });
            }
          },
          {
            label: '3) Rewards Area',
            items: [
              {
                label: '3.1) Rewards',
                command: () => {
                  this.props.router.push({ pathname: '/admin/reward' });
                }
              },
              {
                label: '3.2) Discount Codes',
                command: () => {
                  this.props.router.push({ pathname: '/admin/rewardDiscountCode' });
                }
              },
              {
                label: '3.2) Rewards Pool',
                command: () => {
                  this.props.router.push({ pathname: '/admin/rewardPool' });
                }
              }
            ]
          },
          {
            label: '4) Placements',
            command: () => {
              this.props.router.push({ pathname: '/admin/displayBlock' });
            }
          },
          {
            label: '5) Content Layout',
            command: () => {
              this.props.router.push({ pathname: '/admin/codeBlock' });
            }
          },
          {
            label: '6) Email',
            items: [
              {
                label: '6.1) Email',
                command: () => {
                  this.props.router.push({ pathname: '/admin/emailTemplate' });
                }
              },
              {
                label: '6.2) Associate Email to Campaign Version',
                command: () => {
                  this.props.router.push({ pathname: '/admin/associateEmailToCampaignVersion' });
                }
              }
            ]
          },
        ]
      },
      {
        label: 'Admin',
        icon: 'fas fa-cogs',
        items: [
          {
            label: 'Demo Store',
            icon: 'fa fa-shopping-bag',
            command: () => {
              this.props.router.push({ pathname: '/admin/demoStore' });
            }
          },
          {
            label: 'User Management',
            icon: 'fas fa-users',
            command: () => {
              this.props.router.push({ pathname: '/admin/userManagement' });
            }
          },
          {
            label: 'Affiliate Networks',
            icon: 'fa fa-plug',
            command: () => {
              this.props.router.push({ pathname: '/admin/affiliate' });
            }
          },
          {
            label: 'Orders',
            icon: 'fa-shopping-basket',
            command: () => {
              this.props.router.push({ pathname: '/admin/order' });
            }
          },
          {
            label: 'Email Templates',
            icon: 'fa-envelope',
            command: () => {
              this.props.router.push({ pathname: '/admin/emailTemplateType' });
            }
          },
          {
            label: 'User Blacklist',
            icon: 'fa-ban',
            command: () => {
              this.props.router.push({ pathname: '/admin/userBlacklist' });
            }
          },
          {
            label: 'Country Codes',
            icon: 'fa-map-signs',
            command: () => {
              this.props.router.push({ pathname: '/admin/countryCode' });
            }
          },
          {
            label: 'Support',
            icon: 'fa-ticket',
            items: [
              {
                label: 'Client Setup',
                icon: 'fa-bolt',
                command: () => {
                  this.props.router.push({ pathname: '/admin/wizard' });
                }
              },
              {
                label: 'Client Explorer',
                icon: 'fa-eye',
                command: () => {
                  this.props.router.push({ pathname: '/admin/explorer' });
                }
              },
              {
                label: 'Live Integration',
                icon: 'fa-code',
                command: () => {
                  this.props.router.push({ pathname: '/admin/live/integration' });
                }
              }
            ]
          }
        ]
      },
      {
        label: 'Reports',
        icon: '"fa fa-table',
        items: [
          {
            label: 'Live Clients',
            command: () => {
              this.props.router.push({ pathname: '/admin/reports/liveClients' });
            }
          },
          {
            label: 'Live Rewards',
            command: () => {
              this.props.router.push({ pathname: '/admin/reports/liveRewards' });
            }
          },
          {
            label: 'Live Rewards Basic',
            command: () => {
              this.props.router.push({ pathname: '/admin/reports/liveRewardsBasic' });
            }
          },
          {
            label: 'Financial',
            command: () => {
              window.location.href = `${config.URL.BASEREPORT}`;
            }
          }          
        ]
      }
    ];

    let builDynamics = (menu, collection) => {

      menu.forEach((father) => {

        let newItem = { 
          label : father.label,
          items : null,
          disabled : !father.enabled,
          command: () => {
            if(father.urlPath){
              this.props.router.push({ pathname: '/admin/dynamicPage/passThrough/go' });
              setTimeout(() => {                
                this.props.router.replace({ pathname: `${father.urlPath.replace(':_id', father._id)}`});              
              }, 2000);
              
            }            
          }
        };

        if(father.iconClass){
          newItem.icon = father.iconClass;
        }

        let childs = [];
        builDynamics(dynamicMenus.filter(child => child.childOfId == father._id), childs);

        if(childs.length > 0){
          newItem.items = childs;
        }

        collection.push(newItem);
      });
    };

    if(dynamicMenus && dynamicMenus.length > 0){
      builDynamics(dynamicMenus.filter(d => !d.childOfId), items);
    }    

    items.push(      {
      label: 'Client Explorer',
      icon: 'fa-eye',
      command: () => {
        this.props.router.push({ pathname: '/admin/explorer' });
      }
    });

    return (
      <LoadingOverlay
        active={this.props.loadSipperEnabled}
        spinner
        text='Loading...'
        styles={{
          spinner: (base) => ({
            ...base,
            width: '100px',
          }),
          wrapper: {
            overlay: 'auto'
          }
        }}
      >
        <div className="grid-container main-container">
          <Menubar model={items}>
            <Button
              label="Logout"
              icon="fa-sign-out"
              style={{ marginLeft: 4, marginTop: 4, marginRight: 6 }}
              onClick={this.logout}
            />
          </Menubar>
          <GlobalMessages message={this.props.globalMessage} />
          <div>
            <div className="content grid-100 reverb-admin">{this.props.children}</div>
          </div>
          <Footer />
        </div>
      </LoadingOverlay>
    );
  }
}

export default connect(store => {
  return {
    globalMessage: store.globalMessages.message,
    countries: store.adminLayout.layoutCountriesGet,
    loadSipperEnabled: store.loadSpinner.enabled
  };
})(Layout);
