import React from 'react';
import { browserHistory } from 'react-router';
import { connect } from 'react-redux';
import _ from 'lodash';
import validator from 'validator';

import * as campaignActions from '../../../../actions/admin/campaignActions';
import * as globalMessageActions from '../../../../actions/globalMessageActions';

import FormElement from '../../../shared/form/FormElement';
import ClientsDropbox from '../../../shared/dropbox/ClientsDropbox';

import CurrencyDropbox from '../../../shared/dropbox/CurrencyDropbox';

export class CampaignCreate extends React.Component {

  constructor(props) {
    super(props);

    const {
      campaignDetails = {
        clientId:'',
        expiry:'',
        startDate:'',
        description:'',
        shortUrlCustomStringComponent:'',
        countryId: '',
        active: '',
        superCampaign: false,
        directShare: false,
        soretoTag: true,
        orderOriginCurrency: null
      }
    } = props;

    this.state = {
      campaignDetails,
      errors: {},
      displayErrorMessage: false,
      formSuccessfullySubmitted: false,
    };

  }

  componentWillUpdate(nextProps){
    if(this.props.campaignObject !=nextProps.campaignObject){
      if(_.get(nextProps,'campaignObject.data')){
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Error: ' + nextProps.campaignObject.data.message,
          timeout: 10000
        }));
      }
    }
  }

  componentDidMount() {
    this.setCountry();
  }

  componentDidUpdate(prevProps) {
    if(this.props.countryId !== prevProps.countryId) {
      this.setCountry();
    }
  }
  setCountry() {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'countryId', this.props.countryId);
    this.setState({campaignDetails});
  }

  onChange(name, {target}) {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, name, target.value);
    this.setState({campaignDetails});
  }

  onChangeExpiry = (e) => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'expiry', e.value);
    this.setState({campaignDetails});
  }

  onChangeStartDate = (e) => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'startDate', e.value);
    this.setState({campaignDetails});
  }

  onClientChange = (e) => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'clientId', e.value);
    this.setState({campaignDetails});
  }

  onChangeOrderOriginalCurrencyStatus = e => {
    const {campaignDetails} = this.state;
    _.set(campaignDetails, 'orderOriginCurrency', e.value);
    this.setState({campaignDetails});
  };


  onSubmit(){
    this.setState({ displayErrorMessage: false });
    const {campaignDetails,errors} = this.state;
    let checkErrors = false;

    this.validateForm();

    for( const key in errors ) {
      if( errors[key] && key !== 'shortUrlCustomStringComponent' && key !== 'country') {
        checkErrors = true;
        this.setState({displayErrorMessage: true});
        this.props.dispatch(globalMessageActions.showMessage({
          type: 'error',
          message: 'Please fill all the required',
          timeout: 10000
        }));
        return;
      }
    }

    if( errors['shortUrlCustomStringComponent']) {
      checkErrors = true;
      this.setState({displayErrorMessage: true});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'error',
        message: 'Short Url Custom String must be lowercase and shorter then 20 characters with only "_" or "-"' ,
        timeout: 10000
      }));
      return;
    }

    if( errors['country']) {
      checkErrors = true;
      this.setState({displayErrorMessage: true});
      this.props.dispatch(globalMessageActions.showMessage({
        type: 'error',
        message: 'Please select your country at Menu Bar' ,
        timeout: 10000
      }));
      return;
    }

    if( !checkErrors ) {
      this.props.dispatch(campaignActions.newCampaign(campaignDetails))
        .then(created => browserHistory.push({ pathname: `/admin/campaign/${created._id}` , state: { fromCreatePage: true } }));
    }
  }

  validateForm() {
    const { campaignDetails, errors } = this.state;

    errors[ 'clientId' ] = validator.isEmpty(campaignDetails.clientId.toString());
    errors[ 'expiry' ] = validator.isEmpty(campaignDetails.expiry.toString());
    errors[ 'startDate' ] = validator.isEmpty(campaignDetails.startDate.toString());
    errors[ 'description' ] = validator.isEmpty( campaignDetails.description.toString());
    errors[ 'active' ] = validator.isEmpty( campaignDetails.active.toString() );
    errors[ 'directShare' ] = validator.isEmpty( campaignDetails.directShare.toString() );
    errors[ 'soretoTag' ] = validator.isEmpty( campaignDetails.soretoTag.toString() );
    errors[ 'country' ] = this.props.countryId === 'all';

    if (!validator.isEmpty( campaignDetails.shortUrlCustomStringComponent.toString())){
      errors[ 'shortUrlCustomStringComponent' ] = (!validator.matches(campaignDetails.shortUrlCustomStringComponent.toString(),/^[a-z\d_-]{2,20}$/));
    }
    else {
      errors[ 'shortUrlCustomStringComponent' ] = validator.isEmpty( campaignDetails.shortUrlCustomStringComponent.toString());
    }

    return this.setState({ errors });
  }

  render (){
    const {campaignDetails = { info: {} }, errors, displayErrorMessage } = this.state;

    const {
      clientId,
      expiry,
      description,
      startDate,
      shortUrlCustomStringComponent,
      superCampaign,
      active,
      directShare,
      soretoTag,
      type,
      orderOriginCurrency,
    } = campaignDetails;

    return (
      <div className="campaign-new-campaign">
        <h3>Create campaign</h3>
        <form  className="c-form--flex">

          <FormElement
            label='Country'
            name='country'
            type='text'
            value= { (this.props.countryId !== 'all' && this.props.countries) ? this.props.countries.find(c => c._id === this.props.countryId).name : ''}
            id='form-country'
            required
            disabled={true}
            error={errors.country}

          />

          <ClientsDropbox
            label="Client"
            required='true'
            onChange={this.onClientChange}
            error={errors.clientId}
          />

          <FormElement
            label='Client Id'
            name='clientId'
            type='text'
            onChange={this.onChange.bind(this, 'clientId')}
            value={clientId}
            error={errors.clientId}
            id='form-clientId'
            disabled
          />

          <FormElement
            label='Name'
            name='description'
            type='text'
            onChange={this.onChange.bind(this, 'description')}
            value={description}
            error={errors.description}
            id='form-description'
            required
          />

          <FormElement
            label='Expiry (This field uses UTC time)'
            name='expiry'
            type='calendarWithTime'
            onChange={(e) => this.onChangeExpiry(e)}
            value={expiry}
            id='expiry'
            required
            style={{ marginLeft: '5px', width: '300px'}}
          />

          <FormElement
            label='Start Date (This field uses UTC time)'
            name='startDate'
            type='calendarWithTime'
            onChange={(e) => this.onChangeStartDate(e)}
            value={startDate}
            id='start-date'
            required
            style={{ marginLeft: '5px', width: '300px' }}
          />

          <FormElement
            label='Short Url Custom String (lower case, 20 chars max, can have "_", "-" )'
            name='shortUrlCustomStringComponent'
            type='text'
            onChange={this.onChange.bind(this, 'shortUrlCustomStringComponent')}
            value={shortUrlCustomStringComponent ? shortUrlCustomStringComponent : ''}
            error={errors.shortUrlCustomStringComponent}
            id='form-shortUrlCustomStringComponent'
            required
          />

          <FormElement
            label='Active'
            name='active'
            type='select'
            onChange={this.onChange.bind(this, 'active')}
            value={active}
            error={errors.active}
            id='form-active'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Super Campaign'
            name='superCampaign'
            type='select'
            onChange={this.onChange.bind(this, 'superCampaign')}
            value={superCampaign}
            error={errors.active}
            id='form-active'
            required>

            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Direct share link allowed'
            name='directShare'
            type='select'
            onChange={this.onChange.bind(this, 'directShare')}
            value={directShare}
            error={errors.directShare}
            id='form-directShare'
            required
          >
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Soreto tag allowed'
            name='soretoTag'
            type='select'
            onChange={this.onChange.bind(this, 'soretoTag')}
            value={soretoTag}
            error={errors.soretoTag}
            id='form-soretoTag'
            required
          >  
            <option value=""> </option>
            <option value="true">true</option>
            <option value="false">false</option>
          </FormElement>

          <FormElement
            label='Type'
            name='type'
            type='select'
            onChange={this.onChange.bind(this, 'type')}
            value={type}
            id='form-type'
            required
          >  
            <option value="on_site_referral">On Site Referral</option>
            <option value="marketplace">Marketplace</option>
          </FormElement>

          <CurrencyDropbox 
            onChange={e => this.onChangeOrderOriginalCurrencyStatus(e)}
            value={orderOriginCurrency}
          />

        </form>
        <br />
        <button style={{ marginTop: '10px' }} onClick={()=>this.onSubmit()}>Create campaign</button>

        <button
          className="button icon-left"
          onClick={browserHistory.goBack}>
          Back
        </button>
      </div>
    );
  }
}

export default connect((store) => {
  return {
    loginCtx: store.loginCtx.loginCtx,
    campaignObject: store.adminCampaign.requests,
    countryId: store.adminLayout.layoutCountrySet,
    countries: store.adminLayout.layoutCountriesGet
  };
})(CampaignCreate);
